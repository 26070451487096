import React from "react";
import { Link } from "react-router-dom";

const LandingPage = () => {
  return (
    <div className="landing-page">
      <header>
        <h1 className="name">tadhg o'brien</h1>
      </header>
      <main className="landing-page-links">
        <Link to="/projects" className="landing-page-link">projects</Link>
        <Link to="https://www.linkedin.com/in/tadhgobrien" className="landing-page-link" target="_blank" rel="noopener noreferrer">linkedin</Link>
      </main>
      <footer>
      </footer>
    </div>
  );
};

export default LandingPage;