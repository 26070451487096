import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import './ProjectsPage.css';
import 'bulma/css/bulma.min.css';

const API_BASE_URL = 'https://lambdalens-api.tadhgo.com';
const acc = "Rq35kFIDga2VcI9bqkJJU9dPgw4cehRZ4YPa3XGl" // rate limited

const categorizeRegion = (region) => {
  if (region.startsWith('us-') || region.startsWith('ca-')) return 'North America';
  if (region.startsWith('eu-') || region === 'il-central-1') return 'Europe';
  if (region.startsWith('ap-')) return 'Asia Pacific';
  if (region.startsWith('sa-')) return 'South America';
  if (region.startsWith('af-')) return 'Africa';
  if (region.startsWith('me-')) return 'Middle East';
  return 'Other';
};

const parseOSInfo = (osRelease) => {
  const pairs = osRelease.split(';').map(pair => pair.trim());
  const osInfo = {};
  pairs.forEach(pair => {
    const [key, ...valueParts] = pair.split('=');
    osInfo[key] = valueParts.join('='); // Rejoin in case value contains '='
  });
  return {
    prettyName: osInfo['PRETTY_NAME'] || 'Unknown',
    variantId: osInfo['VARIANT_ID'] || 'Unknown',
    platformId: osInfo['PLATFORM_ID'] || 'Unknown',
    supportEnd: osInfo['SUPPORT_END'] || 'Not specified',
  };
};

const PythonRuntimeDisplay = () => {
  const [runtimeData, setRuntimeData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedRuntime, setSelectedRuntime] = useState(null);
  const [expandedCategories, setExpandedCategories] = useState({});

  useEffect(() => {
    const fetchRuntimeData = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${API_BASE_URL}/runtimes/current`, {
          headers: {
            'x-api-key': acc
          }
        });
        if (response.status === 429) {
          throw new Error('Rate limit exceeded. Please try again later.');
        }
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        console.log(data);
        setRuntimeData(data);
        setError(null);
      } catch (e) {
        setError(e.message);
      } finally {
        setLoading(false);
      }
    };
    fetchRuntimeData();
  }, []);

  const pythonVersions = useMemo(() =>
    [...new Set(runtimeData.map(item => item.runtime))],
    [runtimeData]
  );

  useEffect(() => {
    if (pythonVersions.length > 0 && !selectedRuntime) {
      setSelectedRuntime(pythonVersions[0]);
    }
  }, [pythonVersions, selectedRuntime]);

  const filteredData = useMemo(() =>
    selectedRuntime ? runtimeData.filter(item => item.runtime === selectedRuntime) : runtimeData,
    [runtimeData, selectedRuntime]
  );

  const groupedData = useMemo(() => {
    const grouped = filteredData.reduce((acc, item) => {
      const category = categorizeRegion(item.region);
      if (!acc[category]) acc[category] = [];
      acc[category].push(item);
      return acc;
    }, {});

    Object.keys(grouped).forEach(category => {
      grouped[category].sort((a, b) => a.region.localeCompare(b.region));
    });

    return grouped;
  }, [filteredData]);

  const summaryData = useMemo(() => {
    if (filteredData.length === 0) return null;
    const item = filteredData[0];
    const osInfo = parseOSInfo(item.osInfo.os_release);
    return {
      osVersion: osInfo.prettyName,
      variantId: osInfo.variantId,
      platformId: osInfo.platformId,
      compiler: item.pythonInfo.compiler,
      pythonVersion: item.pythonInfo.version,
      buildDate: item.pythonInfo.build_date,
      supportEndDate: osInfo.supportEnd,
      kernelVersion: item.osInfo.release
    };
  }, [filteredData]);

  const toggleCategory = (category) => {
    setExpandedCategories(prev => ({
      ...prev,
      [category]: !prev[category]
    }));
  };

  if (loading) return <progress class="progress is-small is-info" max="100">60%</progress>;
  if (error) return <article className="message is-danger"><div className="message-header">
    <p>Error: {error}</p>
  </div>
  </article>;

  return (
    <div className="python-runtime-display">
      <h2 className="title is-3">AWS Lambda Python Runtime Information</h2>
      <p className="subtitle is-6">Tracks the underlying Python and OS environment details on AWS Lambda Functions in all 29 commercial AWS Regions</p>
      <p className="subtitle is-6">Refreshes every 30 minutes</p>
      <div className="version-selector">
        {pythonVersions.map(version => (
          <button
            key={version}
            onClick={() => setSelectedRuntime(version)}
            className={selectedRuntime === version ? 'active' : ''}
          >
            {version}
          </button>
        ))}
      </div>
      {summaryData && (
        <div className="summary-card">
          <h3>Runtime Summary for {selectedRuntime}</h3>
          <p><strong>OS Version:</strong> {summaryData.osVersion}</p>
          <p><strong>Variant ID:</strong> {summaryData.variantId}</p>
          <p><strong>Platform ID:</strong> {summaryData.platformId}</p>
          <p><strong>Compiler:</strong> {summaryData.compiler}</p>
          <p><strong>Python Version:</strong> {summaryData.pythonVersion}</p>
          <p><strong>Build Date:</strong> {summaryData.buildDate}</p>
          <p><strong>OS Support End Date:</strong> {summaryData.supportEndDate}</p>
          <p><strong>Kernel Version:</strong> {summaryData.kernelVersion}</p>
        </div>
      )}
      {Object.entries(groupedData).map(([category, regions]) => (
        <div key={category} className="region-category">
          <h3 onClick={() => toggleCategory(category)} className="category-header">
            {category} ({regions.length})
          </h3>
          {expandedCategories[category] && (
            <div className="region-grid">
              {regions.map(item => {
                const osInfo = parseOSInfo(item.osInfo.os_release);
                return (
                  <div key={item.region} className="region-card">
                    <h4>{item.region}</h4>
                    <p><strong>Version:</strong> {item.pythonInfo.version}</p>
                    <p><strong>Build Date:</strong> {item.pythonInfo.build_date}</p>
                    <p><strong>First Detected:</strong> {new Date(item.firstDetectedTimestamp).toLocaleDateString()}</p>
                    <p><strong>OS:</strong> {osInfo.prettyName}</p>
                    <p><strong>Variant ID:</strong> {osInfo.variantId}</p>
                    <p><strong>Platform ID:</strong> {osInfo.platformId}</p>
                    <p><strong>Kernel:</strong> {item.osInfo.release}</p>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

const ProjectsPage = () => {
  return (
    <div className="projects-page">
      <nav className="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <li><Link to="/">Home</Link></li>
          <li className="is-active"><a href="/" aria-current="page">Projects</a></li>
        </ul>
      </nav>
      <main>
        <PythonRuntimeDisplay />
      </main>
    </div>
  );
};

export default ProjectsPage;